<template>
	<div class="all">
		<!-- 第一部分 -->
		<div class="main">
			<div class="bgcolor" v-if="y < 76 && width > 992">
				<nav class="navbar navbar-expand-lg bg-body-tertiary fixed-top"
					style="background-color: transparent !important; opacity: 1">
					<div class="container">
						<a class="navbar-brand" href="#"><img src="./assets/images/LOGO.png" alt=""
								style="height: 3.125rem" /></a>
						<button class="navbar-toggler" type="button" data-bs-toggle="collapse"
							data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
							aria-label="Toggle navigation" style="background-color: white">
							<span class="navbar-toggler-icon"></span>
						</button>
						<div class="collapse navbar-collapse" id="navbarNav" style="flex-grow: 0">
							<ul class="navbar-nav" style="background-color: transparent; text-align: center">
								<li class="nav-item">
									<a style="color: white:  !important;" class="nav-link active" aria-current="page"
										href="#TOP">首页</a>
								</li>
								<li class="nav-item">
									<a style="color: white" class="nav-link" href="#TOPT">关于我们及产品介绍</a>
								</li>
								<li class="nav-item">
									<a class="nav-link" style="color: white" href="#TOPTH">企业视频介绍</a>
								</li>
								<li class="nav-item">
									<a class="nav-link" style="color: white" href="#TOPF">优势和亮点</a>
								</li>
								<li class="nav-item">
									<a class="nav-link" style="color: white" href="#TOPFI">规划与发展</a>
								</li>
							</ul>
						</div>
					</div>
				</nav>
			</div>
			<div class="bgcolor" v-else>
				<nav class="navbar navbar-expand-lg bg-body-tertiary fixed-top"
					style="background-color: black !important; opacity: 0.8">
					<div class="container">
						<a class="navbar-brand" href="#"><img src="./assets/images/LOGO.png" alt=""
								style="height: 3.125rem" /></a>
						<button class="navbar-toggler" type="button" data-bs-toggle="collapse"
							data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
							aria-label="Toggle navigation" style="background-color: white">
							<span class="navbar-toggler-icon"></span>
						</button>
						<div class="collapse navbar-collapse" id="navbarNav" style="flex-grow: 0">
							<ul class="navbar-nav" style="background-color: black; text-align: center">
								<li class="nav-item">
									<a style="color: white" class="nav-link active" aria-current="page"
										href="#TOP">首页</a>
								</li>
								<li class="nav-item">
									<a style="color: white" class="nav-link" href="#TOPT">关于我们及产品介绍</a>
								</li>
								<li class="nav-item">
									<a style="color: white" class="nav-link" href="#TOPTH">企业视频介绍</a>
								</li>
								<li class="nav-item">
									<a class="nav-link" style="color: white" href="#TOPF">优势和亮点</a>
								</li>
								<li class="nav-item">
									<a class="nav-link" style="color: white" href="#TOPFI">规划与发展</a>
								</li>
							</ul>
						</div>
					</div>
				</nav>
			</div>
		</div>

		<!-- 第二部分 -->
		<div class="main" v-if="width > 992">
			<div class="bodyOne" id="TOPT">
				<div class="container" style="display: flex">
					<div>
						<div class="bgtext">ABOUT US</div>
						<div class="titleText">关于我们及产品介绍</div>
						<div class="">
							<div class="littleBox"></div>
							<div class="titleEnglish">ABOUT US AND PRODUCT INTRODUCTION</div>
						</div>
						<div class="titleMore">
							盛世全城是一个解决创业难，就业难，经营难，消费难的数智一体化生活服务平台，隶属于山东六信网络科技有限公司，公司创立于2018年，是一家集营销策划，数智应用，技术研发，实业科技，人才资本，多位一体，可无限兼容多重产业链，领先赋能行业的创新型企业。
						</div>
					</div>

					<div style="display: flex">
						<div>
							<img class="img-fluid" style="padding-top: 6.25rem" src="./assets/images/little1.png"
								alt="" />
						</div>
						<div>
							<img class="img-fluid" style="padding-top: 3.125rem" src="./assets/images/little2.png"
								alt="" />
						</div>
						<div>
							<img class="img-fluid" style="padding-top: 7.5rem" src="./assets/images/little3.png"
								alt="" />
						</div>
					</div>
					<div></div>
				</div>
				<div class="container" style="text-align: left">
					<div class="row" style="margin-left: 6.25rem">
						<div class="col-lg-3 col-md-6 mb10">
							<img src="./assets/images/lio1.png" alt="" />
							<div style="margin-left: 3.125rem">
								<div class="saas" style="
										width: 223px;
										height: 19px;
										font-size: 20px;
										font-family: HarmonyOS_Sans_SC_Bold;
										color: #000000;
										line-height: 20px;
										margin-top: 14px;
										margin-bottom: 15px;
										font-weight: 900;
									">
									SAAS系统
								</div>
								<div class="saasTitle">
									SAAS提供完整的软件解决方案，无需商家自己开发系统，只要注册入驻盛世全城智慧系统，就可以独立展示商家产品，实现独立经营，完成数字化转型！
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-md-6 mb10">
							<img src="./assets/images/lio2.png" alt="" />
							<div style="margin-left: 3.125rem">
								<div class="saas" style="
										width: 223px;
										height: 19px;
										font-size: 20px;
										font-family: HarmonyOS_Sans_SC_Bold;
										color: #000000;
										line-height: 20px;
										margin-top: 14px;
										margin-bottom: 15px;
										font-weight: 900;
									">
									广告发布系统
								</div>
								<div class="saasTitle">
									商家用户可根据自己的经营需要和需求发布广告做有效推广的营销功能！
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-md-6 mb10">
							<img src="./assets/images/lio3.png" alt="" />
							<div style="margin-left: 3.125rem">
								<div class="saas" style="
										width: 223px;
										height: 19px;
										font-size: 20px;
										font-family: HarmonyOS_Sans_SC_Bold;
										color: #000000;
										line-height: 20px;
										margin-top: 14px;
										margin-bottom: 15px;
										font-weight: 900;
									">
									商城系统
								</div>
								<div class="saasTitle">
									一个全球化的涵盖各种产品的线上购物商城功能!
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-md-6 mb10">
							<img src="./assets/images/lio4.png" alt="" />
							<div style="margin-left: 3.125rem">
								<div class="saas" style="
										width: 223px;
										height: 19px;
										font-size: 20px;
										font-family: HarmonyOS_Sans_SC_Bold;
										color: #000000;
										line-height: 20px;
										margin-top: 14px;
										margin-bottom: 15px;
										font-weight: 900;
									">
									外送点单系统
								</div>
								<div class="saasTitle">
									实现线上下单，根据所下订单，完成线下快速配送的系统功能！
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-md-6 mb10">
							<img src="./assets/images/lio5.png" alt="" />
							<div style="margin-left: 3.125rem">
								<div class="saas" style="
										width: 223px;
										height: 19px;
										font-size: 20px;
										font-family: HarmonyOS_Sans_SC_Bold;
										color: #000000;
										line-height: 20px;
										margin-top: 14px;
										margin-bottom: 15px;
										font-weight: 900;
									">
									直播系统
								</div>
								<div class="saasTitle">直播短视频营销功能！</div>
							</div>
						</div>
						<div class="col-lg-3 col-md-6 mb10">
							<img src="./assets/images/lio6.png" alt="" />
							<div style="margin-left: 3.125rem">
								<div class="saas" style="
										width: 223px;
										height: 19px;
										font-size: 20px;
										font-family: HarmonyOS_Sans_SC_Bold;
										color: #000000;
										line-height: 20px;
										margin-top: 14px;
										margin-bottom: 15px;
										font-weight: 900;
									">
									供求发布系统
								</div>
								<div class="saasTitle">需求发布服务功能！</div>
							</div>
						</div>
						<div class="col-lg-3 col-md-6 mb10">
							<img src="./assets/images/lio7.png" alt="" />
							<div style="margin-left: 3.125rem">
								<div class="saas" style="
										width: 223px;
										height: 19px;
										font-size: 20px;
										font-family: HarmonyOS_Sans_SC_Bold;
										color: #000000;
										line-height: 20px;
										margin-top: 14px;
										margin-bottom: 15px;
										font-weight: 900;
									">
									求职招聘发布系统
								</div>
								<div class="saasTitle">求职招聘发布服务功能！</div>
							</div>
						</div>
						<div class="col-lg-3 col-md-6 mb10">
							<img src="./assets/images/lio8.png" alt="" />
							<div style="margin-left: 3.125rem">
								<div class="saas" style="
										width: 223px;
										height: 19px;
										font-size: 20px;
										font-family: HarmonyOS_Sans_SC_Bold;
										color: #000000;
										line-height: 20px;
										margin-top: 14px;
										margin-bottom: 15px;
										font-weight: 900;
									">
									积分系统等
								</div>
								<div class="saasTitle">积分核销兑换等营销辅助功能！</div>
							</div>
						</div>

						<div class="col-lg-3 col-md-6 mb10">
							<img src="./assets/images/lio9.png" alt="" />
							<div style="margin-left: 3.125rem">
								<div class="saas" style="
										width: 223px;
										height: 19px;
										font-size: 20px;
										font-family: HarmonyOS_Sans_SC_Bold;
										color: #000000;
										line-height: 20px;
										margin-top: 14px;
										margin-bottom: 15px;
										font-weight: 900;
									">
									聚合支付系统
								</div>
								<div class="saasTitle">分类配比支付收款等功能</div>
							</div>
						</div>

						<div class="col-lg-3 col-md-6 mb10">
							<img src="./assets/images/lio10.png" alt="" />
							<div style="margin-left: 3.125rem">
								<div class="saas" style="
										width: 223px;
										height: 19px;
										font-size: 20px;
										font-family: HarmonyOS_Sans_SC_Bold;
										color: #000000;
										line-height: 20px;
										margin-top: 14px;
										margin-bottom: 15px;
										font-weight: 900;
									">
									电子红包券系统
								</div>
								<div class="saasTitle">商家经营提升业绩辅助功能!</div>
							</div>
						</div>

						<div class="col-lg-3 col-md-6 mb10">
							<img src="./assets/images/lio11.png" alt="" />
							<div style="margin-left: 3.125rem">
								<div class="saas" style="
										width: 223px;
										height: 19px;
										font-size: 20px;
										font-family: HarmonyOS_Sans_SC_Bold;
										color: #000000;
										line-height: 20px;
										margin-top: 14px;
										margin-bottom: 15px;
										font-weight: 900;
									">
									签到抽奖系统
								</div>
								<div class="saasTitle">提升用户关注度及与平台商家之间粘性</div>
							</div>
						</div>


						<div class="col-lg-3 col-md-6 mb10">
							<img src="./assets/images/lio12.png" alt="" />
							<div style="margin-left: 3.125rem">
								<div class="saas" style="
										width: 223px;
										height: 19px;
										font-size: 20px;
										font-family: HarmonyOS_Sans_SC_Bold;
										color: #000000;
										line-height: 20px;
										margin-top: 14px;
										margin-bottom: 15px;
										font-weight: 900;
									">
									分账系统
								</div>
								<div class="saasTitle">账目统计核销及分配</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
		<div class="main" v-else>
			<div class="bodyOne" id="TOPT">
				<div class="container" style="display: flex">
					<div>
						<div class="bgtext">ABOUT US</div>
						<div class="titleText" style="margin-left: 6.875rem">
							关于我们及产品介绍
						</div>
						<div class="">
							<div class="littleBox" style="margin-left: 6.875rem"></div>
							<div class="titleEnglish" style="margin-left: 7.8125rem">
								ABOUT US AND PRODUCT INTRODUCTION
							</div>
						</div>
						<div class="titleMore" style="margin-left: 5rem">
							盛世全城是一个解决创业难，就业难，经营难，消费难的数智一体化生活服务平台，隶属于山东六信网络科技有限公司，公司创立于2018年，是一家集营销策划，数智应用，技术研发，实业科技，人才资本，多位一体，可无限兼容多重产业链，领先赋能行业的创新型企业。
						</div>
					</div>

					<div style="display: flex">
						<div>
							<img class="img-fluid" style="padding-top: 6.25rem" src="./assets/images/little1.png"
								alt="" />
						</div>
						<div>
							<img class="img-fluid" style="padding-top: 3.125rem" src="./assets/images/little2.png"
								alt="" />
						</div>
						<div>
							<img class="img-fluid" style="padding-top: 7.5rem" src="./assets/images/little3.png"
								alt="" />
						</div>
					</div>
					<div></div>
				</div>
				<div class="container" style="text-align: left">
					<div class="row" style="margin-left: 6.25rem">
						<div class="col-lg-3 col-md-6 mb10">
							<img src="./assets/images/lio1.png" alt="" />
							<div style="margin-left: 3.125rem">
								<div class="saas" style="
										width: 223px;
										height: 19px;
										font-size: 20px;
										font-family: HarmonyOS_Sans_SC_Bold;
										color: #000000;
										line-height: 20px;
										margin-top: 14px;
										margin-bottom: 15px;
										font-weight: 900;
									">
									SAAS系统
								</div>
								<div class="saasTitle">
									SAAS提供完整的软件解决方案，无需商家自己开发系统，只要注册入驻盛世全城智慧系统，就可以独立展示商家产品，实现独立经营，完成数字化转型！
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-md-6  mb10">
							<img src="./assets/images/lio2.png" alt="" />
							<div style="margin-left: 3.125rem">
								<div class="saas" style="
										width: 223px;
										height: 19px;
										font-size: 20px;
										font-family: HarmonyOS_Sans_SC_Bold;
										color: #000000;
										line-height: 20px;
										margin-top: 14px;
										margin-bottom: 15px;
										font-weight: 900;
									">
									广告发布系统
								</div>
								<div class="saasTitle">
									商家用户可根据自己的经营需要和需求发布广告做有效推广的营销功能！
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-md-6  mb10">
							<img src="./assets/images/lio3.png" alt="" />
							<div style="margin-left: 3.125rem">
								<div class="saas" style="
										width: 223px;
										height: 19px;
										font-size: 20px;
										font-family: HarmonyOS_Sans_SC_Bold;
										color: #000000;
										line-height: 20px;
										margin-top: 14px;
										margin-bottom: 15px;
										font-weight: 900;
									">
									商城系统
								</div>
								<div class="saasTitle">
									一个全球化的涵盖各种产品的线上购物商城功能!
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-md-6  mb10">
							<img src="./assets/images/lio4.png" alt="" />
							<div style="margin-left: 3.125rem">
								<div class="saas" style="
										width: 223px;
										height: 19px;
										font-size: 20px;
										font-family: HarmonyOS_Sans_SC_Bold;
										color: #000000;
										line-height: 20px;
										margin-top: 14px;
										margin-bottom: 15px;
										font-weight: 900;
									">
									外送点单系统
								</div>
								<div class="saasTitle">
									实现线上下单，根据所下订单，完成线下快速配送的系统功能！
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-md-6  mb10">
							<img src="./assets/images/lio5.png" alt="" />
							<div style="margin-left: 3.125rem">
								<div class="saas" style="
										width: 223px;
										height: 19px;
										font-size: 20px;
										font-family: HarmonyOS_Sans_SC_Bold;
										color: #000000;
										line-height: 20px;
										margin-top: 14px;
										margin-bottom: 15px;
										font-weight: 900;
									">
									直播系统
								</div>
								<div class="saasTitle">直播短视频营销功能！</div>
							</div>
						</div>
						<div class="col-lg-3 col-md-6  mb10">
							<img src="./assets/images/lio6.png" alt="" />
							<div style="margin-left: 3.125rem">
								<div class="saas" style="
										width: 223px;
										height: 19px;
										font-size: 20px;
										font-family: HarmonyOS_Sans_SC_Bold;
										color: #000000;
										line-height: 20px;
										margin-top: 14px;
										margin-bottom: 15px;
										font-weight: 900;
									">
									供求发布系统
								</div>
								<div class="saasTitle">需求发布服务功能！</div>
							</div>
						</div>
						<div class="col-lg-3 col-md-6  mb10">
							<img src="./assets/images/lio7.png" alt="" />
							<div style="margin-left: 3.125rem">
								<div class="saas" style="
										width: 223px;
										height: 19px;
										font-size: 20px;
										font-family: HarmonyOS_Sans_SC_Bold;
										color: #000000;
										line-height: 20px;
										margin-top: 14px;
										margin-bottom: 15px;
										font-weight: 900;
									">
									求职招聘发布系统
								</div>
								<div class="saasTitle">求职招聘发布服务功能！</div>
							</div>
						</div>
						<div class="col-lg-3 col-md-6  mb10">
							<img src="./assets/images/lio8.png" alt="" />
							<div style="margin-left: 3.125rem">
								<div class="saas" style="
										width: 223px;
										height: 19px;
										font-size: 20px;
										font-family: HarmonyOS_Sans_SC_Bold;
										color: #000000;
										line-height: 20px;
										margin-top: 14px;
										margin-bottom: 15px;
										font-weight: 900;
									">
									积分系统等
								</div>
								<div class="saasTitle">积分核销兑换等营销辅助功能！</div>
							</div>
						</div>

						<div class="col-lg-3 col-md-6 mb10">
							<img src="./assets/images/lio9.png" alt="" />
							<div style="margin-left: 3.125rem">
								<div class="saas" style="
										width: 223px;
										height: 19px;
										font-size: 20px;
										font-family: HarmonyOS_Sans_SC_Bold;
										color: #000000;
										line-height: 20px;
										margin-top: 14px;
										margin-bottom: 15px;
										font-weight: 900;
									">
									聚合支付系统
								</div>
								<div class="saasTitle">分类配比支付收款等功能</div>
							</div>
						</div>

						<div class="col-lg-3 col-md-6 mb10">
							<img src="./assets/images/lio10.png" alt="" />
							<div style="margin-left: 3.125rem">
								<div class="saas" style="
										width: 223px;
										height: 19px;
										font-size: 20px;
										font-family: HarmonyOS_Sans_SC_Bold;
										color: #000000;
										line-height: 20px;
										margin-top: 14px;
										margin-bottom: 15px;
										font-weight: 900;
									">
									电子红包券系统
								</div>
								<div class="saasTitle">商家经营提升业绩辅助功能!</div>
							</div>
						</div>

						<div class="col-lg-3 col-md-6 mb10">
							<img src="./assets/images/lio11.png" alt="" />
							<div style="margin-left: 3.125rem">
								<div class="saas" style="
										width: 223px;
										height: 19px;
										font-size: 20px;
										font-family: HarmonyOS_Sans_SC_Bold;
										color: #000000;
										line-height: 20px;
										margin-top: 14px;
										margin-bottom: 15px;
										font-weight: 900;
									">
									签到抽奖系统
								</div>
								<div class="saasTitle">提升用户关注度及与平台商家之间粘性</div>
							</div>
						</div>


						<div class="col-lg-3 col-md-6 mb10">
							<img src="./assets/images/lio12.png" alt="" />
							<div style="margin-left: 3.125rem">
								<div class="saas" style="
										width: 223px;
										height: 19px;
										font-size: 20px;
										font-family: HarmonyOS_Sans_SC_Bold;
										color: #000000;
										line-height: 20px;
										margin-top: 14px;
										margin-bottom: 15px;
										font-weight: 900;
									">
									分账系统
								</div>
								<div class="saasTitle">账目统计核销及分配</div>
							</div>
						</div>



					</div>
				</div>
			</div>
		</div>

		<!-- 第三部分 -->
		<div class="main">
			<div class="bodyTwo" id="TOPTH">
				<div class="container">
					<div class="bgtext" style="color: #192130">
						VIDEO
					</div>
					<div class="titleText" style="color: #f2f2f2">
						企业视频介绍
					</div>
					<div>
						<div class="littleBox" style="border: .1875rem solid #f2f2f2"></div>
						<div class="titleEnglish" style="color: #f2f2f2">
							ENTERPRISE VIDEO INTRODUCTION
						</div>
					</div>
					<div class="videBox" style="">
						<video class="vide" width="100%;" height="100%;" controls preload="meta"
							poster="./assets/images/bigLogo.png">
							<source src="./assets/video/video.mp4" type="video/mp4" />
						</video>
					</div>
				</div>
			</div>
		</div>
		<!-- 第四部分 -->
		<div class="main">
			<div class="bodyThree" id="TOPF" style="background-color: #f3f6f9;">
				<div class="container">
					<div class="bgtext" style="color: #e7e9ec">
						HIGHLIGHTS
					</div>
					<div class="titleText">优势和亮点</div>
					<div>
						<div class="littleBox"></div>
						<div class="titleEnglish">ADVANTAGES AND HIGHLIGHTS</div>
					</div>

					<div>
						<div class="row" style="margin-left: 6.25rem; margin-top: 1.875rem">
							<div class="col-lg-3 col-md-6">
								<div class="">
									<div class="img_div">
										<img class="imgCSS" src="./assets/images/list1.png" alt="" />
										<div class="mask">
											<div class="land"></div>
											<div>
												<h3 style="">01</h3>
											</div>
											<div class="titileSomething line7">
												随着国家出台鼓励促进新型消费创新商业形态的一系列扶持政策，
												新型消费业态必将进入快速发展的轨道当中。
											</div>
										</div>
									</div>

									<div class="saasMore">国家政策扶持</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6">
								<div class="listChange">
									<div class="img_div">
										<img class="imgCSS" src="./assets/images/list2.png" alt="" />
										<div class="mask">
											<div class="land"></div>
											<div>
												<h3 style="">02</h3>
											</div>
											<div class="titileSomething line7">
												随着用户对消费体验和认知需求越来越高，单纯的功能应用场景已满足不了用户的综合需求，
												必将过度诞生一种新的商业消费体现形态。
											</div>
										</div>
									</div>

									<div class="saasMore">满足用户需求</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6">
								<div class="listChange">
									<div class="img_div">
										<img class="imgCSS" src="./assets/images/list3.png" alt="" />
										<div class="mask">
											<div class="land"></div>
											<div>
												<h3 style="">03</h3>
											</div>
											<div class="titileSomething line7">
												盛世全城最大的亮点就是整个应用场景闭环都是利他的，利国利民，利商，利社会!
											</div>
										</div>
									</div>

									<div class="saasMore">应用场景利他</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6">
								<div class="listChange">
									<div class="img_div">
										<img class="imgCSS" src="./assets/images/list4.png" alt="" />
										<div class="mask">
											<div class="land"></div>
											<div>
												<h3 style="">04</h3>
											</div>
											<div class="titileSomething line7">
												盛世全城是全球首家打通线下线上与支付相结合植入最新消费模式帮助实体商家搭建可以实时变现私域流量，可以无限植入，无限扩展，无限兼容各种应用场景的智慧系统！
											</div>
										</div>
									</div>

									<div class="saasMore">无限拓展无限兼容</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6">
								<div class="listChange">
									<div class="img_div">
										<img class="imgCSS" src="./assets/images/list5.png" alt="" />
										<div class="mask">
											<div class="land"></div>
											<div>
												<h3 style="">05</h3>
											</div>
											<div class="titileSomething line7">
												盛世全城致力成为全球本地生活智慧系统第一品牌，共同富裕创业孵化平台！是目前市面上唯一一套能够帮助实体商家搭建自己可以实时变现私域流量的系统。
												这些私域流量不论是自用还是共享都可以实现变现，持续原动力的无限创造价值。
											</div>
										</div>
									</div>

									<div class="saasMore">价值最大化私域流量</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6">
								<div class="listChange">
									<div class="img_div">
										<img class="imgCSS" src="./assets/images/list6.png" alt="" />
										<div class="mask">
											<div class="land"></div>
											<div>
												<h3 style="">06</h3>
											</div>
											<div class="titileSomething line7">
												盛世全城可以根据不同的应用场景实现经销商，消费者，就业者，创业者之间的角色自由切换，
												持续原动力的创造价值，实现价值最大化!
											</div>
										</div>
									</div>

									<div class="saasMore">角色自动切换</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6">
								<div class="listChange">
									<div class="img_div">
										<img class="imgCSS" src="./assets/images/list7.png" alt="" />
										<div class="mask">
											<div class="land"></div>
											<div>
												<h3 style="">07</h3>
											</div>
											<div class="titileSomething line7">
												盛世全城是目前互联网行业内最具备成为下一个独角兽，可超万亿级体量的项目。
											</div>
										</div>
									</div>

									<div class="saasMore">超万亿级体量</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6">
								<div class="listChange">
									<div class="img_div">
										<img class="imgCSS" src="./assets/images/list8.png" alt="" />
										<div class="mask">
											<div class="land"></div>
											<div>
												<h3 style="">08</h3>
											</div>
											<div class="titileSomething line7">
												公司计划五年内成功上市，有望成为"这个行业的全球化领军企业"。
											</div>
										</div>
									</div>

									<div class="saasMore">成为领军企业</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 第五部分 -->
		<div class="main">
			<div class="bodyFour" id="TOPFI" style="background-color: beige">
				<div class="container">
					<div class="bgtext" style="color: #2672d4">
						PLANNING
					</div>
					<div class="titleText" style="color: #fff">
						规划与发展
					</div>
					<div>
						<div class="littleBox" style="border: 3px solid #fff"></div>
						<div class="titleEnglish" style="color: #fff">
							PLANNING AND DEVELOPMENT
						</div>
					</div>
					<div class="titleDistance">
						<text class="textThing" style="line-height: 30px">
							公司会在今年整合合伙人，建立资源链，完成市场内测，完善品牌建立，开展基础人才团队建设，进一步深耕渠道，以海陆空三位一体的招融方式逐步开展资方对接，开展全国服务商，运营中心，城市合伙人的招商工作，并在明年打造样板市场，快速复制全国，扩展数据，拉升估值，规划布局上市流程，用三年时间蓄势相关资源和人才，用4-5年时间打造上市，实现产业深度布局，预计6-10年时间进军国际市场，成为这个行业的全球化领军企业，之后随着6G时代到来，盛世全城将布局数智一体化全场景应用+新型人工智能，打通虚拟与现实互通互融的综合应用场景，实现首家完善完成数智综合应用完全落地的全球性超强独角兽企业，成为全球数智一体化生活服务平台第一品牌！
						</text>
					</div>
					<div class="arrows">
						<img class="img-fluid" src="./assets/images/jiantou1.png" alt="" />
					</div>
					<div style="text-align: center">
						<text class="textThing" style="
								font-size: 30px;
								letter-spacing: 15px;
								width: 75rem;

								text-align: center;
							">打造全球数智生活服务平台第一品牌</text>
					</div>
				</div>
			</div>
		</div>

		<!-- 第六部分 -->
		<div class="botton">
			<div class="container">
				<div style="display: flex; justify-content: space-between">
					<div class="mainBotton">
						<div>
							<div style="margin-top: 10px">
								<img src="./assets/images/LOGO.png" alt="" />
							</div>

							<div class="footerText">山东六信网络科技有限公司</div>
							<div class="footerEnglish">www.shengshiquancheng.com</div>
						</div>
					</div>
					<div style="margin-top: 30px">
						<div style="font-weight: 900; color: #fff; margin-bottom: 5px">
							联系我们
						</div>
						<div style="color: #fff; margin-bottom: 5px">
							地址:山东省临沂市
						</div>
						<div style="color: #fff; margin-bottom: 5px">
							咨询热线:4009965808
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<div style="text-align: center">
				<div class="container" style="display: flex; justify-content: center; align-items: center">
					<div style="">
						<a href="https://beian.miit.gov.cn/" target="_blank"
							style="color: #aaa !important">&nbsp;&nbsp;鲁ICP备2023005847号-1</a>
					</div>

					<div style="margin-right: .625rem">
						<img style="margin-left: .625rem; margin-right: -0.625rem" src="./assets/images/guohui.png"
							alt="" />
						<a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37130202372452"
							target="_blank" style="color: #aaa !important">&nbsp;&nbsp;鲁公网安备 37130202372452号</a>
					</div>
					<div>山东六信网络科技有限公司©版权所有</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup>
import { useScroll } from '@vueuse/core';
import { useWindowSize } from '@vueuse/core';
const { width } = useWindowSize();
const { y } = useScroll(window);
console.log('值得', useScroll(window));
</script>
<style lang="scss">
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

a {
	text-decoration: none !important;
	color: white !important;
}

a:hover {
	color: #fff;
}

li {
	list-style-type: none;
}

.mb10 {
	margin-bottom: 20px;
}

// 首部
.main {

	// 最小
	@media (max-width: 48rem) {

		.bgcolor {
			height: 15.625rem;
		}

		.bodyTwo {
			height: 15.625rem;
		}

		.bodyThree {
			height: 181.25rem;
		}

		.bodyFour {
			height: 62.5rem;
		}

		.bodyOne {
			height: 93.75rem;
		}

		.bgtext {
			font-size: 3.125rem;
		}

		.container .titleText {
			font-size: 1.25rem;
		}

		.videBox {
			width: 12.5rem;
			height: 3.125rem;
		}
	}

	// 普遍适配
	@media (min-width: 48rem) {
		.bgcolor {
			height: 25rem;
		}

		.bodyTwo {
			height: 37.5rem;
		}

		.bodyThree {
			height: 96.875rem;
		}

		.bodyFour {
			height: 50rem;
		}

		.bodyOne {
			min-height: 62.5rem;
		}

		.bgtext {
			font-size: 6.25rem;
		}

		.titleText {
			margin-top: -3.75rem;
		}

		.container .titleText {
			font-size: 1.875rem;
		}

		.videBox {
			width: 37.5rem;
			height: 25rem;
		}
	}

	@media (min-width: 62rem) {
		.bgcolor {
			height: 45.0625rem;
		}

		.bodyTwo {
			height: 45.0625rem;
		}

		.bodyThree {
			height: 50rem;
		}

		.bodyFour {
			height: 45.0625rem;
		}

		.bodyOne {
			min-height: 53.125rem;
		}

		.bgtext {
			font-size: 7.5rem;
		}

		.videBox {
			width: 68.75rem;
			height: 31.25rem;
		}
	}
}

.project {
	text-align: center;
}

.bgcolor {
	background-image: url('./assets/images/festBody.png');
	background-size: cover;
	background-position: center 0;
}

/* 标题 */
/* 标题字体加大 */
/* 导航 */

/* 导航栏样式 */
.navbar a {
	color: white;
	text-align: center;
}

/* 鼠标移动到链接的颜色 */
.navbar a:hover {
	border-bottom: 2px solid #f3efef;
}

.bodyOne {
	background-image: url('./assets/images/twoBody.png');
	background-size: cover;
	background-position: center 0;
	overflow: hidden;
}

.bgtext {
	margin-top: 1.875rem;
	font-size: 8.75rem;
	font-family: PingFang-SC-Bold, PingFang-SC;
	font-weight: bold;
	color: #f2f2f2;
	line-height: 80px;
	z-index: 0;
}

.bgtextMore {
	width: 770px;
	height: 105px;
	font-size: 140px;
	font-family: PingFang-SC-Bold, PingFang-SC;
	font-weight: bold;
	color: #192130;
	line-height: 80px;
	z-index: 0;
}

.titleText {
	font-size: 1.875rem;
	font-family: HarmonyOS_Sans_SC_Bold;
	color: #000000;
	line-height: 80px;
	font-weight: 900;
	margin-left: 11.625rem;
}

.titleTextMore {
	position: absolute;
	left: 350px;
	top: 80px;
	width: 18.125rem;
	height: 29px;
	font-size: 30px;
	font-family: HarmonyOS_Sans_SC_Bold;
	color: #fff;
	line-height: 80px;
	font-weight: 900;
}

.littleBoxMore {
	position: absolute;
	border-radius: 50%;
	left: 405px;
	top: 150px;
	width: 15px;
	height: 15px;
	border: 3px solid #fff;
}

.littleBox {
	border-radius: 50%;
	margin-left: 11.75rem;
	margin-top: -0.625rem;
	width: 12px;
	height: 12px;
	border: 3px solid #0c60c0;
}

.titleEnglishMore {
	position: absolute;
	left: 370px;
	top: 119px;
	width: 400px;
	height: 13px;
	font-size: 16px;
	font-family: Gilroy-Bold, Gilroy;
	font-weight: bold;
	color: #fff;
	line-height: 80px;
}

.titleEnglish {
	margin-left: 12.875rem;
	margin-top: -2.8125rem;
	width: 400px;
	height: 13px;
	font-size: 16px;
	font-family: Gilroy-Bold, Gilroy;
	font-weight: bold;
	color: #000000;
	line-height: 80px;
}

.titleMore {
	margin-left: 12.25rem;
	margin-top: 1.25rem;
	margin-right: 1.25rem;
	width: 37.9375rem;
	height: 157px;
	text-align: left;
	text-indent: 2rem;
	line-height: 1.4375rem;
	margin-bottom: 1.875rem;
}

.line7 {
	word-break: break-all;
	display: -webkit-box;
	-webkit-line-clamp: 7;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.img_div {
	border-radius: .625rem;
	display: block;
	position: relative;
	overflow: hidden;
}

.imgCSS {
	height: 100%;
	width: 100%;
	border-radius: 10px;
	display: block;
	cursor: pointer;
	overflow: hidden;
}

.mask {
	position: absolute;
	background: rgba(67, 127, 238, 0.6);
	color: #ffffff;
	opacity: 0;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	border-radius: 10px;
	pointer-events: none;

	.land {
		border-bottom: 4px solid #fff;
		width: 70px;
		position: absolute;
		top: 50px;
	}

	h3 {
		margin-top: 40px;
		width: 200px;
		margin-left: 50px;
		text-indent: 32px;
	}

	.titileSomething {
		// margin-top: 40px;
		width: 200px;
		margin-left: 40px;
		text-indent: 32px;
	}
}

// .mask h3 {
// 	text-align: center;
// 	margin-top: 25%;
// }
.img_div:hover .mask {
	opacity: 1;
}

.saasMore {
	text-align: center;

	height: 19px;
	font-size: 20px;
	font-family: HarmonyOS_Sans_SC_Bold;
	color: #000000;
	line-height: 20px;
	margin-top: 14px;
	margin-bottom: 15px;
	font-weight: 900;
}

.listThing {
	position: absolute;
	left: 50%;
	transform: translate(-50%);
	// left: 305px;
	top: 536px;
	width: 1600px;

	.row .saas {
		width: 223px;
		height: 19px;
		font-size: 20px;
		font-family: HarmonyOS_Sans_SC_Bold;
		color: #000000;
		line-height: 20px;
		margin-top: 14px;
		margin-bottom: 15px;
		font-weight: 900;
	}

	.saasTitle {
		margin-left: 50px;
		width: 229px;
		height: 194px;
		font-size: 14px;
		font-family: PingFang-SC-Regular, PingFang-SC;
		font-weight: 400;
		color: #000000;
		line-height: 20px;
	}
}

// 第三部分
.bodyTwo {
	height: 932px;
	background-image: url('./assets/images/body3.png');
	background-size: cover;
	background-position: center 0;
	overflow: hidden;

	img {
		position: absolute;
		left: 320px;
		top: 0px;
	}

	.videBox {
		margin-top: 2.5rem;
		position: absolute;
		left: 50%;
		transform: translate(-50%);
	}
}

// 第四部分
.bodyThree {
	height: 52rem;
	overflow: hidden;
	// background-position: 100% -13.75rem;
	position: relative;
}

// 第五部分
.bodyFour {
	overflow: hidden;
	background-image: url('./assets/images/body4.png');
	background-repeat: no-repeat;
	background-size: cover;

	position: relative;
}

.textThing {
	margin-top: 12.5rem;
	text-indent: 32px;
	color: #fff;
}

.titleDistance {
	margin-top: 6.25rem;
	text-indent: 2rem;
}

.arrows {
	text-align: center;
}

.botton {
	height: 137px;
	background-color: #25282b;

	position: relative;
}

.mainBotton {
	height: 100%;
	// background-color: #ca2020;

	display: flex;
	justify-content: space-between;

	.footerText {
		width: 290px;
		height: 25px;
		font-size: 24px;
		font-family: FZCHSJW--GB1-0, FZCHSJW--GB1;
		font-weight: normal;
		color: #ffffff;
		line-height: 40px;
	}

	.footerEnglish {
		width: 284px;
		height: 18px;
		font-size: 20px;
		font-family: FZCHSJW--GB1-0, FZCHSJW--GB1;
		font-weight: normal;
		color: #ffffff;
		line-height: 40px;
	}
}

/* 底部 */
.footer {
	padding: 30px;

	background: #000;
	color: #aaa;
}

/* 响应式布局 - 在屏幕设备宽度尺寸小于 43.75rem 时, 让两栏上下堆叠显示 */
@media screen and (max-width: 700px) {
	.row {
		flex-direction: column;
	}
}

/* 响应式布局 - 在屏幕设备宽度尺寸小于 25rem 时, 让导航栏目上下堆叠显示 */
@media screen and (max-width: 400px) {
	.navbar a {
		float: none;
		width: 100%;
	}
}
</style>
